.library-filters {
  z-index: 6;
  top: $header-height;
  @media (min-width: 1024px) {
    z-index: 4;
    &.is-hidden:not(.unpinned-mode) {
      right: 0;
    }
  }
}

.search-box {
  position: relative;
  display: block;
  max-width: 100%;

  @media (min-width: 768px) {
    max-width: calc(100% - 550px);
    width: 632px;
  }

  .input-group {
    width: 100%;
  }
  .form-control {
    height: $header-height * 0.88;
    padding-left: 15px;
    padding-right: 35px;
    font-size: $f-size-lg;
    border: 0;
    border: 1px solid $c-grey-light !important;
    box-shadow: $box-shadow;
    z-index: 4;

    @include placeholder($c-black);

    &:focus {
      box-shadow: none;
      @include placeholder(transparent);
    }
  }
  .input-group .fa-search,
  .input-group .fa-flask,
  .input-group .fa-times {
    position: absolute;
    line-height: $header-height;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;
  }
  .input-group .fa-search,
  .input-group .fa-flask {
    right: 20px;
    font-size: $f-size-lg;
    cursor: pointer;
  }
  .input-group .fa-times {
    display: none;
    right: 50px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    font-size: $f-size-sm;
    color: $c-grey;
    border: 1px solid $c-grey;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      color: $c-black;
      border-color: $c-black;
    }
  }
  .input-group.is-active .fa-times {
    display: block;
  }
  .modal-tip-label {
    font-size: $f-size-xs;
    color: $c-primary;
    border-bottom: 1px solid $c-primary-light;
    cursor: pointer;
  }
  .search-icon-wrapper {
    padding: 0;
    background-color: transparent;
    border: none;
  }
}

.search-button {
  position: relative;
  display: block;
  border-radius: 0;
  border: 0;
  float: right;
  width: $header-height;
  height: $header-height;
  line-height: $header-height + 2px;
  padding: 0;
  z-index: 3;
  @media (min-width: 1024px) {
    display: none;
  }

  &,
  &:hover,
  &:active {
    @if $theme == light {
      color: $c-black;
    } @else {
      color: $c-white;
    }
    box-shadow: none;
  }

  &.is-active {
    position: absolute;
    width: $header-height;
    margin-left: 0;
    @media (min-width: 454px) {
      right: 400px;
    }
    @media (min-width: 1024px) {
      position: relative;
      right: 0;
    }
  }

  .fa {
    margin: 0 5px;
  }
}

@media (max-width: 1023px) {
  .searchButton-open span {
    display: none;
  }
}

.searchButton-open {
  display: inline-block;
}

.search-button.is-active {
  .searchButton-open {
    display: none;
  }
}

.search-suggestions {
  display: none !important;
  position: absolute;
  top: $header-height - 1px;
  left: 0;
  width: 100%;
  background: $c-white;
  z-index: 40;
  color: $c-black;
  border-bottom: 1px solid $c-grey-light;
  line-height: 1em;
  &.is-active {
    display: block;
    box-shadow: $box-shadow;
  }
}

.search-suggestions-item {
  position: relative;
  margin: 0;
  border-top: 1px solid $c-grey-light;
  &:hover {
    background: $c-grey-lighten;
  }

  a {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding-left: 35px;
    margin-right: 40px;
    height: $header-height;
    line-height: 1.3em;
    color: $c-black;
    text-decoration: none;
  }
  b {
    background-color: transparentize(yellow, 0.4);
    box-shadow: -2px 0 0 0 transparentize(yellow, 0.4), 2px 0 0 0 transparentize(yellow, 0.4);
  }

  .fa {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
}

.search-suggestions-all {
  display: block;
  width: 100%;
  line-height: $header-height;
  padding: 0;
  background: $c-grey-lighten;
  border: 0;
  border-top: 1px solid $c-grey-light;
  box-shadow: none !important;
  outline: none !important;
  &:hover {
    background: $c-grey-light;
  }

  .fa {
    margin-right: 5px;
  }
  button {
    width: 100%;
    text-align: left;
  }
}

.filters-box {
  .empty-state {
    color: $c-grey;
    text-align: center;
    padding: 30px;

    .fa {
      display: block;
      font-size: $f-size-xl;
      margin: 0 auto 5px;
    }
  }
}

.filters {
  display: none;
}

.admin-filter {
  & > ul > li:first-child {
    color: #2b56c1;
  }

  & > ul > li.spaced {
    margin-top: 15px;
  }

  span,
  label {
    color: #2b56c1;
  }

  input {
    border: #2b56c1;
  }
}

.search__filter {
  list-style-type: none;
  margin: 0;
  padding: 15px;
  color: $c-grey-dark;
  display: flex;
  flex-direction: column;

  &:not(:last-child),
  .form-group:not(:last-child) & {
    border-bottom: 1px solid $c-grey-light;
  }

  > li:first-child {
    position: relative;
    margin: 0;
    width: 100%;
    font-weight: 300;
    font-style: normal;
    font-family: $f-special;
    margin-bottom: 0;
    color: $c-grey-dark;
    line-height: inherit;
    label {
      font-size: $f-size;
      text-transform: none;
      letter-spacing: 0;
      font-weight: 300;
    }
  }

  > li {
    line-height: 19px;
    width: 50%;
  }

  > li.wide {
    width: 100%;

    > div {
      display: table;
      width: 100%;
    }
  }

  .fa-caret-left {
    color: $c-success;
  }
  .fa-caret-left + span {
    margin-left: 5px;
    color: $c-success;
  }

  .fa-times {
    color: $c-danger;
  }
  .fa-times + span {
    margin-left: 5px;
    color: $c-danger;
    cursor: pointer;
  }
}

.search__filter--type {
  background: transparent;

  input,
  label {
    cursor: pointer;
    margin: 0;
  }
  label {
    font-family: $f-regular !important;
    font-size: $f-size !important;
    font-weight: bold;
  }
  .btn {
    width: 97%;
    margin-bottom: 5px;
  }
}

.search__filter--radiobutton,
.search__filter--single__selection,
.search__filter--multiple__selection {
  input {
    position: relative;
    margin-right: 2px;
    cursor: pointer;
  }
}

.search__filter--single__selection li {
  width: 100%;
}

.search__filter--radiobutton {
  .fa {
    border-radius: 50%;
  }
}

.search__filter--list {
  ol {
    overflow-y: scroll;
    height: 130px;
    background: $c-white;
    border: 1px solid $c-grey;
    border-top: 0;
    list-style-type: none;
    padding: 0;

    li {
      border-top: 0;
      padding: 5px 12px;
    }
    input {
      margin-right: 5px;
    }
  }
}

.search__filter--selected__media {
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  img {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
    background-color: #fafafa;
  }

  .video-container {
    margin: 0px;
    height: 100%;

    > div:first-child {
      padding-bottom: initial;
      overflow: initial;
      height: 100%;
      display: flex;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}

.search__filter--selected__media-toolbar {
  order: -1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 12px;

  button {
    height: 23px;
    background: #e8e7ec;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    padding: 2px 8px 3px 8px;
    border: 1px solid #cfd8dc;
  }
}

.switcher-wrapper {
  float: right;
  span {
    font-family: $f-regular;
    font-size: $f-size-sm;
    letter-spacing: 0.1em;
    color: $c-grey;
  }
  span.is-active {
    color: $c-primary;
    font-weight: bold;
  }

  div {
    display: inline;
  }

  input {
    display: none;
  }
}

.switcher {
  $switcher-height: 9px;
  position: relative;
  display: inline-block;
  width: 20px;
  height: $switcher-height;
  margin: 0 3px;
  background-color: lighten($c-primary, 10%);
  border-radius: $switcher-height / 2;
  border: 1px solid $c-primary;
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    width: $switcher-height - 2;
    height: $switcher-height - 2;
    background-color: $c-white;
    border-radius: 50%;
  }
  :checked + & {
    &:after {
      right: auto;
      left: 0;
    }
  }
}

.nested-strict {
  display: inline;
  position: absolute;
  right: -5px;

  & > div,
  & > label {
    float: left;
    cursor: pointer;
  }
}

.multiselect {
  padding: 0;
  margin: 0;
  list-style-type: none;

  &:not(:last-child) {
    border-bottom: 1px dotted $c-grey;
  }

  & > .no-options-message span {
    color: $c-grey;
  }
}

.multiselectChild {
  padding: 0 0 0 18px;
}

.multiselectLabel {
  font-family: $f-special;
  position: relative;
  margin-bottom: 8px;

  input.form-control {
    border: 0;
    border-bottom: 1px solid $c-grey;
    border-radius: 0;
    box-shadow: none;
    padding-left: 26px;

    &:focus {
      border-bottom-color: $c-black;
    }
  }

  .fa {
    color: $c-grey;
    position: absolute;
    top: 9px;
    left: 6px;
  }

  input.form-control:focus ~ .fa {
    color: $c-black;
  }
}
.multiselectLabel-icon {
  float: right;
  font-size: $f-size-lg;
}

.multiselectItem {
  display: flex;
  align-items: center;
  position: relative;
}

.multiselectItem-name {
  color: $c-grey-dark;
  max-width: calc(100% - 50px);

  svg {
    font-size: $f-size-xs;
    width: 1.1em !important;
  }

  .multiselectItem-label:hover & {
    border-bottom: 1px solid;
    margin-bottom: -1px;
  }

  a {
    color: $c-grey-dark;
    font-size: 1em;
    text-transform: none;
    letter-spacing: 0;
    font-weight: 300;
    &:hover {
      text-decoration: none;
    }
  }
}

.multiselectItem-results {
  position: absolute;
  top: 0;
  right: 15px;
  font-size: $f-size;
  font-weight: 300;
  width: 30px;
  line-height: 24px;
  height: 24px;
  padding-right: 3px;
  color: $c-grey-dark;
  text-align: right;
}

.multiselectItem-action {
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 100%;
  font-size: $f-size-sm;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  border: 1px solid $c-grey;
  border-radius: $border-radius;
  &:hover {
    border-color: $c-black;
    cursor: pointer;
  }
}

.multiselectItem-button {
  position: absolute;
  top: 0;
  right: 0;
  font-size: $f-size-sm;
  font-weight: 700;
  width: 50px;
  line-height: 24px;
  height: 24px;
  padding: 0px 3px;
  color: $c-grey-dark;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  &:hover {
    border-color: $c-black;
    background: rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
}

.multiselectItem-input {
  display: none;
}

.multiselectItem-icon {
  width: 20px;
  font-size: $f-size-lg;
  color: $c-grey;

  &:first-of-type {
    position: relative;
    top: 1px;
    left: 1px;
  }
}

.multiselectItem-label {
  display: -webkit-flex;
  display: flex;
  width: 100%;
}

.multiselectItem--all {
  label {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: $f-size-sm;
    position: relative;
    top: -1px;
  }
}

.multiselectActions {
  padding-top: 8px;
  position: relative;
  font-size: 12px;
  .form-group {
    position: relative;
    display: inline-block;
    svg {
      position: absolute;
      top: 10px;
      right: 10px;
      color: $c-black;
      cursor: pointer;
    }
  }

  .form-control {
    border: 1px solid $c-grey-light;
    border-radius: 0;
    font-size: $f-size-sm;
    width: auto;
    padding: 2px 30px 0 10px;
    margin-bottom: 5px;
    @include placeholder($c-black);

    &:focus {
      outline: none;
      box-shadow: none;
      border-color: $c-grey;
      @include placeholder(transparent);
    }
  }

  .total-options {
    button {
      background: none;
      border: none;
      color: #2b56c1;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .btn {
    display: inline-block;
    padding: 0 3px;
    margin-right: 15px;
    height: 24px;
    line-height: 24px;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid transparent;
    color: $c-black;
    background: transparent;
    box-shadow: none !important;

    &:hover,
    &:focus {
      border-color: $c-black;
      background: transparent;
    }

    .fa {
      margin-right: 10px !important;
    }
  }
}

.multiselectItem {
  line-height: 1.2em;
  padding: 5px 0;
  width: 100%;

  &.no-top-level-select {
    .multiselectItem-icon,
    .multiselectItem-name {
      cursor: pointer;
    }
  }
}

.multiselectItem-icon {
  .checkbox-empty {
    display: block;
    color: $c-grey;
  }
  .checkbox-checked {
    display: none;
    color: $c-success;
  }
  .checkbox-partial {
    display: none;
    color: $c-success;
  }
  .checkbox-group {
    display: none;
    color: $c-success;
  }
  .chevron-right {
    display: none;
  }
  .chevron-down {
    display: none;
  }

  &.no-select {
    .checkbox-empty {
      display: none;
    }
    .chevron-right {
      display: block;
    }

    &.expanded {
      .chevron-right {
        display: none;
      }
      .chevron-down {
        display: block;
      }
    }
  }
}

.multiselectItem-input:disabled {
  & + label {
    .checkbox-empty {
      display: none;
    }
    .checkbox-checked {
      display: block;
      color: $c-grey-dark;
    }
    .checkbox-partial {
      display: none;
    }
    .checkbox-group {
      display: none;
    }
  }
}

.multiselectItem-input[data-state='3'] {
  & + label {
    .checkbox-empty {
      display: none;
    }
    .checkbox-checked {
      display: none;
    }
    .checkbox-group {
      display: block;
    }
    .checkbox-partial {
      display: none;
    }
  }

  & ~ .multiselectItem-label .multiselectItem-name,
  & ~ .multiselectItem-results {
    font-weight: bold;
    color: $c-black;
  }
}
.multiselectItem-input[data-state='2'] {
  & + label {
    .checkbox-empty {
      display: none;
    }
    .checkbox-checked {
      display: block;
    }
    .checkbox-group {
      display: none;
    }
  }

  & ~ .multiselectItem-label .multiselectItem-name,
  & ~ .multiselectItem-results {
    font-weight: bold;
    color: $c-black;
  }
}
.multiselectItem-input[data-state='1'] {
  & + label {
    .checkbox-empty {
      display: none;
    }
    .checkbox-checked {
      display: none;
    }
    .checkbox-partial {
      display: block;
    }
    .checkbox-group {
      display: none;
    }
  }

  & ~ .multiselectItem-label .multiselectItem-name,
  & ~ .multiselectItem-results {
    font-weight: 500;
    color: $c-grey-dark;
  }
}

.nested-selector {
  padding: 15px;
  border-bottom: 1px solid $c-grey-light;
}

.documentTypes-title {
  position: relative;
  margin: 0;
  width: 100%;
  font-weight: 300;
  font-style: normal;
  font-family: $f-special;
  margin-bottom: 0;
  color: $c-black;
  line-height: inherit;
}

.documentTypes-selector .multiselectItem-input:checked,
.relationshipFilters .multiselectItem-input:checked {
  & + label {
    .checkbox-empty {
      display: none;
    }
    .checkbox-checked {
      display: block;
    }
  }

  & ~ .multiselectItem-label .multiselectItem-name,
  & ~ .multiselectItem-results {
    font-weight: bold;
    color: $c-black;
  }
}

.DatePicker__From,
.DatePicker__To,
.Numeric__From,
.Numeric__To {
  position: relative;
  float: left;
  width: 100%;
  background: $c-white;
  border-radius: 4px;

  > span {
    display: inline-block;
    line-height: 34px;
    width: 60px;
    position: absolute;
    color: $c-grey-dark;
    padding-left: 10px;
  }
  & > div {
    width: 100%;
    z-index: 1;
  }
  input {
    padding-left: 70px;
    padding-right: 0;
    background: transparent;
  }
}

.DatePicker__From,
.DatePicker__To {
  input {
    cursor: pointer;
  }
}

.DatePicker__From,
.Numeric__From {
  margin-bottom: 5px;
}

.multidate {
  .add {
    margin-bottom: 5px;
  }

  &-item {
    margin-bottom: 15px;
    // overflow: hidden;
    position: relative;
  }

  &-range {
    width: 100%;
    padding-right: 15px;
  }
}

.snippet-list {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: 0;
    font-size: $f-size;
    line-height: 1.6em;
    width: 100%;
    a,
    span {
      padding: 5px 5px 5px 10px;
      outline: none;
    }
  }

  li:not(:last-of-type) {
    border-bottom: 1px solid $c-grey-light;
  }

  li.snippet-list-item.fulltext-snippet:hover {
    background-color: $c-grey-lighten;
  }

  li.snippet-list-item.selected {
    background-color: $c-primary-lighten;
  }

  li.snippet-list-item-header {
    float: left;
    width: 100%;
    font-weight: 300;
    font-style: normal;
    font-family: $f-special;
    min-width: 30px;
    line-height: 2em;
    font-size: $f-size;
    padding: 15px 5px 0 5px;
  }

  li.snippet-list-item-header a {
    padding: 0;
  }

  a .page-number {
    font-size: $f-size-lg;
    font-weight: bold;
    padding-right: 5px;
  }

  a .snippet-text {
    color: $c-black;
    font-size: $f-size-sm;
  }

  a {
    text-decoration: none;
  }

  b {
    font-weight: normal;
    color: #333300;
    background-color: transparentize(yellow, 0.8);
  }
}
